import * as React from "react";
import { useTranslation } from "react-i18next";

import Layout from "../components/layout";
import Seo from "../components/seo";

const NotFoundPage = () => {
  const { t } = useTranslation();
  return (
    <Layout>
      <Seo title="404: Not found" />
      <h1>{t("404component.title")}</h1>
      <p>{t("404component.pageNotFound")}</p>
    </Layout>
  );
};

export default NotFoundPage;
